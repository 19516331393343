import isEqual from "lodash/isEqual"

import { AdditionalService } from "src/helpers/autoservice-core-backend/types"
import { checkAdditionalServiceType } from "src/helpers/checkAdditionalServiceType"
import { checkServiceType } from "src/helpers/checkServiceType"

import { filterOutExpressAppointment } from "src/helpers/filterOutExpressAppointment"

import { filterOutReplacementCar } from "src/helpers/filterOutReplacementCar"

import { hasReplacementCarAdditionalService } from "src/helpers/isReplacementCarSelected"

import { GarageService } from "./garageActions"
import { mergeServiceFlowData } from "./serviceFlow"
import { InspectionAdditionalService, ServiceCategory } from "./types"

const initialState: ServiceCategory[] = []

const serviceCategoriesAction = {
  clearServiceCategories: () =>
    mergeServiceFlowData({ serviceCategories: initialState }),

  updateServiceCategoriesAndGarage: (
    category: ServiceCategory | null,
    isExpressAppointmentExcluded: boolean = false,
    isReplacementCarAlreadyRequested?: boolean,
  ) => {
    if (isReplacementCarAlreadyRequested && category) {
      category = filterOutReplacementCar(category)
    }

    if (!category) {
      return
    }

    mergeServiceFlowData((state) => {
      const serviceCategories = state.serviceCategories || []
      const oldCategories = [...serviceCategories]

      const categoryIndex = serviceCategories.findIndex((s) =>
        findCategoryById(s, category.internalServiceCategoryId),
      )

      if (categoryIndex > -1) {
        serviceCategories[categoryIndex] = category
      } else {
        serviceCategories.push(category)
      }

      state.serviceCategories = isExpressAppointmentExcluded
        ? serviceCategories.map(filterOutExpressAppointment)
        : serviceCategories

      const shouldRemoveGarages = !isEqual(
        oldCategories,
        state.serviceCategories,
      )

      if (shouldRemoveGarages) {
        state.detailedGarage = undefined
        state.timeSlot = undefined
        state.priceBeforeDiscount = undefined
        state.servicePrices = undefined
        state.priceAfterDiscount = undefined
        state.discountAmount = undefined
      }

      return state
    })
  },

  updateExpressAppoinmentAdditionalService: (
    expressAppointmentAdditionalService?: AdditionalService,
    isExpressAppointmentExcluded: boolean = false,
  ) => {
    if (!expressAppointmentAdditionalService) {
      return
    }

    let serviceCategories: ServiceCategory[] | null = []

    mergeServiceFlowData((state) => {
      serviceCategories = state.serviceCategories || []

      const categoryIndex = serviceCategories.findIndex((s) =>
        checkServiceType.isWheelChangeService(s.internalServiceCategoryId),
      )

      if (categoryIndex < 0) {
        return state
      }

      if (isExpressAppointmentExcluded) {
        state.serviceCategories = serviceCategories.map(
          filterOutExpressAppointment,
        )
      } else {
        serviceCategories[categoryIndex].additionalServices = [
          ...serviceCategories[categoryIndex].additionalServices,
          expressAppointmentAdditionalService,
        ]
      }

      return state
    })
  },

  updateDigitalServiceBookletEntryAdditionalServiceInPitstopOffer: (
    digitalServiceBookletEntryAdditionalService?: AdditionalService,
    isDigitalServiceBookletEntryExcluded: boolean = false,
  ) => {
    if (!digitalServiceBookletEntryAdditionalService) {
      return
    }

    let serviceCategories: GarageService[] | null = []
    let inspectionAdditionalServices: InspectionAdditionalService[] | null = []

    mergeServiceFlowData((state) => {
      serviceCategories = state.servicePrices || []
      inspectionAdditionalServices = state.inspectionAdditionalServices || []

      const categoryIndex = serviceCategories.findIndex((s) =>
        checkServiceType.isInspectionService(s.internalServiceId),
      )

      if (categoryIndex < 0) {
        return state
      }

      if (isDigitalServiceBookletEntryExcluded) {
        state.inspectionAdditionalServices =
          inspectionAdditionalServices.filter(
            (a) =>
              !checkAdditionalServiceType.isDigitalServiceBookletEntry(
                a.internalAdditionalServiceId,
              ),
          )
      } else {
        state.inspectionAdditionalServices = [
          ...inspectionAdditionalServices,
          {
            internalAdditionalServiceId:
              digitalServiceBookletEntryAdditionalService.internalAdditionalServiceId,
            amountPaid: digitalServiceBookletEntryAdditionalService.price ?? 0,
            germanDisplayName:
              digitalServiceBookletEntryAdditionalService.title,
          },
        ]
      }
      return state
    })
  },

  updateReplacementCarAdditionalServiceInOfferFlow: (
    replacementCarAdditionalService?: AdditionalService,
    isReplacementCarExcluded: boolean = false,
  ) => {
    if (!replacementCarAdditionalService) {
      return
    }

    let serviceCategories: GarageService[] | null = []
    let inspectionAdditionalServices: InspectionAdditionalService[] | null = []

    mergeServiceFlowData((state) => {
      state.isReplacementCarRequested = !isReplacementCarExcluded
      state.timeSlot = undefined
      serviceCategories = state.servicePrices || []
      inspectionAdditionalServices = state.inspectionAdditionalServices || []

      if (isReplacementCarExcluded) {
        state.inspectionAdditionalServices =
          inspectionAdditionalServices.filter(
            (a) =>
              !checkAdditionalServiceType.isReplacementCar(
                a.internalAdditionalServiceId,
              ),
          )
      } else {
        state.inspectionAdditionalServices = [
          ...inspectionAdditionalServices,
          {
            internalAdditionalServiceId:
              replacementCarAdditionalService.internalAdditionalServiceId,
            amountPaid: replacementCarAdditionalService.price ?? 0,
            germanDisplayName: replacementCarAdditionalService.title,
          },
        ]
      }
      return state
    })
  },

  updateReplacementCarAdditionalService: (
    replacementCarAdditionalService?: AdditionalService,
    isReplacementCarExcluded: boolean = false,
  ) => {
    if (!replacementCarAdditionalService) {
      return
    }

    let serviceCategories: ServiceCategory[] | null = []

    mergeServiceFlowData((state) => {
      serviceCategories = state.serviceCategories || []

      if (!serviceCategories.length) {
        return state
      }

      if (isReplacementCarExcluded) {
        state.serviceCategories = serviceCategories.map(filterOutReplacementCar)
      } else {
        serviceCategories[0].additionalServices = [
          ...serviceCategories[0].additionalServices,
          replacementCarAdditionalService,
        ]
      }

      return state
    })
  },

  addAdditionalService: (
    additionalService: AdditionalService,
    categoryId?: string,
  ) => {
    if (!categoryId) {
      return
    }

    mergeServiceFlowData((state) => {
      const serviceCategories = state.serviceCategories
      if (!serviceCategories?.length) {
        return state
      }

      const categoryIndex = serviceCategories.findIndex((category) =>
        findCategoryById(category, categoryId),
      )

      if (categoryIndex !== -1) {
        const updatedCategory = {
          ...serviceCategories[categoryIndex],
          additionalServices: [
            ...serviceCategories[categoryIndex].additionalServices,
            additionalService,
          ],
        }

        const newState = {
          ...state,
          serviceCategories: [
            ...serviceCategories.slice(0, categoryIndex),
            updatedCategory,
            ...serviceCategories.slice(categoryIndex + 1),
          ],
        }

        return newState
      }

      return state
    })
  },

  removeAdditionalService: (
    additionalServiceId?: string,
    categoryId?: string,
  ) => {
    if (!additionalServiceId || !categoryId) {
      return
    }

    mergeServiceFlowData((state) => {
      const serviceCategories = state.serviceCategories
      if (!serviceCategories?.length) {
        return state
      }

      const categoryIndex = serviceCategories.findIndex((category) =>
        findCategoryById(category, categoryId),
      )

      if (categoryIndex !== -1) {
        const updatedAdditionalServices = serviceCategories[
          categoryIndex
        ].additionalServices.filter(
          (service) =>
            service.internalAdditionalServiceId !== additionalServiceId,
        )

        const updatedCategory = {
          ...serviceCategories[categoryIndex],
          additionalServices: updatedAdditionalServices,
        }

        const newState = {
          ...state,
          serviceCategories: [
            ...serviceCategories.slice(0, categoryIndex),
            updatedCategory,
            ...serviceCategories.slice(categoryIndex + 1),
          ],
        }

        return newState
      }

      return state
    })
  },

  removeCategory: (id?: string) => {
    if (!id) {
      return
    }

    mergeServiceFlowData((state) => {
      if (state.serviceCategories) {
        const category = state.serviceCategories.find((s) =>
          findCategoryById(s, id),
        )

        const replacementCarAdditionalService =
          category?.additionalServices.find(hasReplacementCarAdditionalService)

        state.serviceCategories = state.serviceCategories.filter(
          (s) => !findCategoryById(s, id),
        )

        // Transfer the replacement car to the other service if the one with it is deleted
        if (
          replacementCarAdditionalService &&
          !!state.serviceCategories.length
        ) {
          serviceCategoriesAction.updateReplacementCarAdditionalService(
            replacementCarAdditionalService,
            false,
          )
        }
      }

      return state
    })
  },

  filterInspectionOrOtherServices: (isInspectionOnlySelected: boolean) => {
    mergeServiceFlowData((state) => {
      if (state.serviceCategories) {
        state.serviceCategories = state.serviceCategories?.filter(
          (service) =>
            isInspectionOnlySelected ===
            checkServiceType.isInspectionService(
              service.internalServiceCategoryId,
            ),
        )
      }
      return state
    })
  },
}

export default serviceCategoriesAction

function findCategoryById(category: ServiceCategory, id?: string) {
  if (!id) {
    return false
  }

  const isBrakeChangeService = checkServiceType.isBrakeChangeService(id)

  return isBrakeChangeService
    ? checkServiceType.isBrakeChangeService(category.internalServiceCategoryId)
    : category.internalServiceCategoryId === id
}
