import { useServiceFlowData } from "src/states/serviceFlow"
import { ServiceCategory } from "src/states/serviceFlow/types"
import { CoreApiServiceCategoryIdEnum } from "src/types/autoservice-core-backend"

type UseIsCategorySelectedProps = {
  serviceCategoryId: CoreApiServiceCategoryIdEnum
  categories?: ServiceCategory[]
}

type UseIsCategorySelected = (props: UseIsCategorySelectedProps) => boolean

export const useIsCategorySelected: UseIsCategorySelected = ({
  serviceCategoryId,
  categories,
}) => {
  const { serviceCategories } = useServiceFlowData()

  const isServiceSelected = (categories || serviceCategories)?.find(
    (category) => category.internalServiceCategoryId === serviceCategoryId,
  )

  return !!isServiceSelected
}
