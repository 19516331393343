import { Service } from "./autoservice-core-backend/types"

export const checkServiceType = {
  isHUAUService: (serviceId?: string) => serviceId === "HU_AU",
  isHUAUPackageService: (serviceId?: string) =>
    serviceId === "HU_AU_PRE_CHECK_COMBINATION",

  isBrakeDiscChangeService: (serviceId?: string) =>
    serviceId === "BRAKE_DISC_CHANGE",

  isBrakePadChangeService: (serviceId?: string) =>
    serviceId === "BRAKE_PAD_CHANGE",

  isBrakeChangeService: (serviceId?: string) =>
    checkServiceType.isBrakeDiscChangeService(serviceId) ||
    checkServiceType.isBrakePadChangeService(serviceId),

  isWheelChangeService: (serviceId?: string) => serviceId === "WHEEL_CHANGE",

  isWheelChangePromotionService: (service?: Service | string) => {
    //TODO refactor
    if (typeof service === "string") {
      return service === "WHEEL_CHANGE_TIRE_STORAGE_AND_BALANCING_COMBINATION"
    }
    return (
      service?.internalServiceId ===
      "WHEEL_CHANGE_TIRE_STORAGE_AND_BALANCING_COMBINATION"
    )
  },

  isWheelChangeOrPromotion: (serviceId?: string) =>
    checkServiceType.isWheelChangeService(serviceId) ||
    checkServiceType.isWheelChangePromotionService(serviceId),

  isTireBalancingService: (serviceId?: string) =>
    serviceId === "TIRE_BALANCING",

  isDiagnoseService: (serviceId?: string) => serviceId === "DIAGNOSE",

  isInspectionService: (serviceId?: string) => serviceId === "BASIC_INSPECTION",

  isAirConditioningService: (serviceId?: string) =>
    serviceId === "AIR_CONDITIONING",

  isAirConditioningInspectionAfter2017Service: (serviceId?: string) =>
    serviceId === "AIR_CONDITIONING_INSPECTION_AFTER_2017",

  isAirConditioningInspectionDesifectionAfter2017Service: (
    serviceId?: string,
  ) => serviceId === "AIR_CONDITIONING_INSPECTION_AND_DISINFECTION_AFTER_2017",

  isAirConditioningAfter2017Service: (serviceId?: string) =>
    checkServiceType.isAirConditioningInspectionAfter2017Service(serviceId) ||
    checkServiceType.isAirConditioningInspectionDesifectionAfter2017Service(
      serviceId,
    ),
}
