import { useEffect, useState } from "react"

/**
 * This script checks if an AB-Tasty variable was added to the window object.
 *
 * Please ALWAYS add a comment with the issue number on top of when the hook is used so we can find it later.
 * E.g.: AUT-1234
 */
export const useABTestVariationValue = (key: string) => {
  const [windowVariable, setWindowVariable] = useState<any>()

  useEffect(() => {
    if (!!window[key as any]) {
      setWindowVariable((window as any)[key])
    } else {
      // If window variable is not available, listen for changes
      const interval = setInterval(() => {
        if (!!window[key as any]) {
          // Set the state once the window variable is available and clear the interval
          clearInterval(interval)
          setWindowVariable((window as any)[key])
        }
      }, 100)

      // Clear interval on component unmount
      return () => clearInterval(interval)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !!windowVariable
}
