import { checkAdditionalServiceType } from "src/helpers/checkAdditionalServiceType"
import {
  InspectionAdditionalService,
  ServiceCategory,
} from "src/states/serviceFlow/types"

import { AdditionalService } from "./autoservice-core-backend/types"

export function isReplacementCarSelected(categories?: ServiceCategory[]) {
  return !!categories?.some(hasReplacementCar)
}

export function hasReplacementCar(category?: ServiceCategory | null) {
  return (
    !!category &&
    category.additionalServices.some(hasReplacementCarAdditionalService)
  )
}

export function hasReplacementCarAdditionalService(
  additionalService: AdditionalService | InspectionAdditionalService,
) {
  return checkAdditionalServiceType.isReplacementCar(
    additionalService.internalAdditionalServiceId,
  )
}
