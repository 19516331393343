import { FC, PropsWithChildren } from "react"

import { create } from "src/helpers/bem"

import styles from "./Separator.module.scss"

const bem = create(styles, "Separator")

export type SeparatorProps = PropsWithChildren<{
  variant: "primary" | "secondary" | "alternative"
  className?: string
}>

export const Separator: FC<SeparatorProps> = ({
  variant,
  children,
  className,
}) => (
  <div
    className={bem(
      undefined,
      {
        [variant]: true,
      },
      className,
    )}
  >
    {!!children && <span className={bem("label")}>{children}</span>}
  </div>
)
