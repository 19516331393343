import { getTranslationMessages } from "src/hooks/translation"
import { formatters } from "src/translations/formatters"

export const formatPriceInformation = (price?: number | null) => {
  const messages = getTranslationMessages()
  const { free, included } = messages.components.service.steps.summary

  const currencyFormatter = formatters.currency

  if (typeof price === "number") {
    return price === 0 ? free : currencyFormatter.format(price)
  }

  if (price === null) {
    return included
  }

  return price ?? "-"
}
