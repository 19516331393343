import {
  CoreApiBookingResponsePriceCalculationComponent,
  CoreApiGarageNpsResponse,
} from "src/types/autoservice-core-backend"

import { mergeServiceFlowData } from "."

export type GarageNetwork =
  | "UNKNOWN_NETWORK"
  | "NETTO_REIFEN"
  | "PITSTOP"
  | "PWS"
  | "REIFF"

export type DmsProviderType = "PITSTOP" | "SFK" | "NO_DMS_PROVIDER_TYPE"

export type GarageServiceDayOfWeek =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY"

export type GarageServiceHoursV2 = {
  dayOfWeek: GarageServiceDayOfWeek
  time: string[][]
  openingOnlyOnRequest: boolean
}

export type GarageCoordinates = {
  latitude: number
  longitude: number
}

export type Garage = {
  id: string
  name: string
  street: string
  city: string
  postalCode: string
  phoneNumber: string
  coordinates: GarageCoordinates
  serviceHoursV2: GarageServiceHoursV2[]
  email: string
  googlePlaceId?: string
  dmsProviderType?: DmsProviderType
  reviewCalculations?: CoreApiGarageNpsResponse
}

export type GarageAdditionalService = {
  additionalServiceId: string
  priceBeforeDiscount: number
  priceAfterDiscount: number
  discountAmount: number
}

export type GarageService = {
  internalServiceId: string
  priceBeforeDiscount: number
  priceAfterDiscount: number
  discountAmount: number
  additionalServices: GarageAdditionalService[]
  priceCalculationComponents?: CoreApiBookingResponsePriceCalculationComponent[]
}

export type PromotionWhichIsValidForGarage = {
  code: string
  description: string
  value: number
  discountType: "FIXED_AMOUNT" | "PERCENTAGE" | "OVERRIDE_PRICE" | "NONE"
  earliestValidAppointmentTime?: string
  latestValidAppointmentTime?: string
  promotionStartTime: string
  promotionEndTime: string
  servicesDiscountedByPromotion: string[]
  isDiscountAppliedToEntireBooking: boolean
  isUserLoginRequired: boolean
  garageNetworksPartOfPromotion: GarageNetwork[]
  isUniquelyGeneratedCode: boolean
  garageBadgeText?: string
}

export type DetailedGarage = {
  garage: Garage
  googleRating?: number
  googleRatingCount?: number
  googleDrivingDistance?: number
  services?: GarageService[]
  nextAvailableDay?: string
  discountAmount?: number
  priceBeforeDiscount?: number
  priceAfterDiscount?: number
  promotionWhichIsValidForGarage?: PromotionWhichIsValidForGarage
}

const initialState: DetailedGarage | undefined = undefined

const garageActions = {
  clearSelectedGarage: () => {
    mergeServiceFlowData((state) => {
      if (!state.detailedGarage) return state

      state.detailedGarage = initialState
      return state
    })
  },

  setSelectedGarage: (detailedGarage: DetailedGarage) =>
    mergeServiceFlowData({ detailedGarage }),

  updateGarageServices: (services: DetailedGarage["services"]) => {
    mergeServiceFlowData((state) => {
      if (!state.detailedGarage) return state

      state.detailedGarage.services = services
      return state
    })
  },

  updateGarageTotalPrice: (
    totalPrice: DetailedGarage["priceAfterDiscount"],
  ) => {
    mergeServiceFlowData((state) => {
      if (!state.detailedGarage) return state

      state.detailedGarage.priceAfterDiscount = totalPrice
      return state
    })
  },
}

export default garageActions
