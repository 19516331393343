import { useRef } from "react"

import {
  addNotificationToast,
  NotificationToastData,
  removeNotificationToast,
} from "src/states/notificationToasts"

export type UseNotificationsHook = () => {
  notify: (data: NotificationToastData) => void
  removeNotification: () => void
}

export const useNotifications: UseNotificationsHook = () => {
  const notificationId = useRef<number | null>(null)

  const removeNotification = () => {
    removeNotificationToast(notificationId.current)
  }

  const notify = ({
    content,
    variant = "info",
    autoclose = true,
    autocloseTimeout,
    icon,
    showOncePerSession,
    link,
  }: NotificationToastData) => {
    removeNotification()
    notificationId.current = addNotificationToast({
      content,
      variant,
      autoclose,
      autocloseTimeout,
      icon,
      showOncePerSession,
      link,
    })
  }

  return {
    notify,
    removeNotification,
  }
}
