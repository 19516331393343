import { FC, PropsWithChildren } from "react"

import { create } from "src/helpers/bem"

import styles from "./InputError.module.scss"

const bem = create(styles, "InputError")

export type InputErrorProps = PropsWithChildren<{
  id: string
}>

export const InputError: FC<InputErrorProps> = ({ id, children }) => (
  <small role="alert" id={id} className={bem()}>
    {children}
  </small>
)
