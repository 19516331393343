import { isPitstopGarage } from "src/helpers/isPitstopGarage"
import { useServiceFlowData } from "src/states/serviceFlow"

import { useIsCategorySelected } from "./serviceSelection/useIsCategorySelected"
import { useFlow } from "./useFlow"

export const useInspectionFlows = () => {
  const { isInspectionOfferFlow } = useFlow()
  const { detailedGarage } = useServiceFlowData()
  const isInspectionCategorySelected = useIsCategorySelected({
    serviceCategoryId: "BASIC_INSPECTION",
  })
  const isPitstop = isPitstopGarage(detailedGarage?.garage?.dmsProviderType)
  const isPitstopInspectionFlow =
    isPitstop && isInspectionCategorySelected && !isInspectionOfferFlow

  return {
    isInspectionCategorySelected,
    isInspectionOfferFlow,
    isPitstopInspectionFlow,
  }
}
