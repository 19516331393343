import {
  Formik,
  Form as BaseForm,
  FormikConfig,
  FormikProps,
  FormikValues,
} from "formik"
import { ReactNode } from "react"

import { Button, ButtonProps } from "../button"
import { create } from "src/helpers/bem"

import styles from "./Form.module.scss"
import { scrollToError } from "./helpers/scrollToError"
import { ScrollToFieldError } from "./ScrollToError"

const bem = create(styles, "Form")

export type FormChildrenProps<T> = Omit<
  FormikProps<T>,
  "handleSubmit" | "handleReset"
> & {
  onSubmitClick: () => Promise<void>
}

export type FormProps<T> = Omit<FormikConfig<T>, "children"> & {
  children: (props: FormChildrenProps<T>) => ReactNode
  submitLabel?: string
  submitButtonProps?: Pick<ButtonProps, "disabled" | "size" | "className"> & {
    variant?: ButtonProps["variant"]
    "data-cy"?: string
  }
  name?: string
  showSubmitButton?: boolean
  additionalButton?: ReactNode
  className?: string
}

export const Form = <T extends FormikValues>({
  children,
  submitLabel,
  submitButtonProps,
  name,
  showSubmitButton = true,
  additionalButton,
  className,
  ...otherProps
}: FormProps<T>) => {
  const modifiers = {
    buttons: !!additionalButton,
  }

  return (
    <Formik<T> {...otherProps}>
      {(formikProps) => {
        const { submitCount, values, validateForm, isSubmitting, errors } =
          formikProps
        const errorsOnDisplay = submitCount > 0 ? errors : {}

        async function onSubmitClick() {
          const validationErrors = !submitCount
            ? await validateForm(values)
            : errors

          scrollToError(validationErrors)
        }

        return (
          <BaseForm
            name={name}
            className={bem(undefined, undefined, className)}
            noValidate
          >
            <ScrollToFieldError />

            {children({
              ...otherProps,
              ...formikProps,
              errors: errorsOnDisplay,
              onSubmitClick,
            })}
            {showSubmitButton && (
              <div className={bem(undefined, modifiers)}>
                {additionalButton}
                <Button
                  data-cy={submitButtonProps?.["data-cy"] ?? "FormSubmit"}
                  size={submitButtonProps?.size}
                  className={bem(
                    "submit-button",
                    undefined,
                    submitButtonProps?.className,
                  )}
                  variant={submitButtonProps?.variant ?? "primary"}
                  type="submit"
                  disabled={isSubmitting || submitButtonProps?.disabled}
                  onClick={onSubmitClick}
                >
                  {submitLabel}
                </Button>
              </div>
            )}
          </BaseForm>
        )
      }}
    </Formik>
  )
}
