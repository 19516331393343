import { FormikErrors } from "formik"
import isEmpty from "lodash/isEmpty"

export const scrollToError = <T>(errors: FormikErrors<T>) => {
  if (errors && !isEmpty(errors)) {
    const elementNamesWithErrors = Object.keys(errors)

    const element =
      elementNamesWithErrors?.length &&
      (document.querySelector(
        `[name=${elementNamesWithErrors[0]}]`,
      ) as HTMLElement)

    if (element) {
      element.scrollIntoView({ block: "center" })
    }
  }
}
