import { checkServiceType } from "src/helpers/checkServiceType"
import { ServiceCategory } from "src/states/serviceFlow/types"

import { BookingService } from "./autoservice-account-backend/types"

export function getIsWheelChangeOnlySelected(
  serviceCategories: ServiceCategory[] | undefined,
) {
  return (
    serviceCategories?.length === 1 &&
    checkServiceType.isWheelChangeOrPromotion(
      serviceCategories[0].internalServiceCategoryId,
    )
  )
}

export function getIsWheelChangeOnlySelectedWithoutAdditional(
  serviceCategories: ServiceCategory[] | BookingService[] | undefined,
) {
  return (
    serviceCategories?.length === 1 &&
    !serviceCategories[0].additionalServices?.length &&
    (("internalServiceCategoryId" in serviceCategories[0] &&
      checkServiceType.isWheelChangeOrPromotion(
        serviceCategories[0].internalServiceCategoryId,
      )) ||
      ("internalServiceId" in serviceCategories[0] &&
        checkServiceType.isWheelChangeOrPromotion(
          serviceCategories[0].internalServiceId,
        )))
  )
}
