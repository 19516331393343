import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

export type ShoppingCart = {
  open: boolean
}

const initalState: ShoppingCart = { open: false }

const { getValue, setValue, useValue } = createSimpleGlobalState(initalState)

export {
  getValue as getShoppingCartState,
  setValue as setShoppingCartState,
  useValue as initializeShoppingCartState,
}

const shoppingCartActions = {
  isOpen: () => {
    return getValue().open
  },
  close: () => {
    setValue({ open: false })
  },
  open: () => {
    setValue({ open: true })
  },
  set: (open: boolean) => {
    setValue({ open })
  },
}

export default shoppingCartActions
