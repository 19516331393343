import { ServiceCategory } from "src/states/serviceFlow/types"

import { checkAdditionalServiceType } from "./checkAdditionalServiceType"

export function filterOutExpressAppointment(service: ServiceCategory) {
  return {
    ...service,
    additionalServices: service.additionalServices?.filter(
      (a) =>
        !checkAdditionalServiceType.isExpressTermin(
          a.internalAdditionalServiceId,
        ),
    ),
  }
}
