import { useRouter } from "next/router"

import { Flow, FlowStep } from "src/config"
import { useUtmProperties } from "src/states/utmProperties/utmProperties"

export type FlowData = {
  flowStep: FlowStep
  flow: Flow
  isInspectionOfferFlow: boolean
  isIframeBookingFlow: boolean
}

export const useFlow = (): FlowData => {
  const { query } = useRouter()
  const { utm_source: utmSource } = useUtmProperties()

  const flow = query?.flow as Flow

  const isInspectionOfferFlow = flow === "2"

  const isIframeBookingFlow =
    flow === "3" || utmSource === "huk24iFrame" || utmSource === "huk24.de"

  const flowStep = isInspectionOfferFlow
    ? "inspektion"
    : (query?.flowStep as FlowStep)

  return {
    flowStep,
    flow,
    isInspectionOfferFlow,
    isIframeBookingFlow,
  }
}
