import { checkAdditionalServiceType } from "src/helpers/checkAdditionalServiceType"
import { checkServiceType } from "src/helpers/checkServiceType"

import { ServiceCategory } from "src/states/serviceFlow/types"

import { BookingService } from "./autoservice-account-backend/types"
import { AdditionalService } from "./autoservice-core-backend/types"

export function isExpressAppointmentSelected(
  categories?: BookingService[] | ServiceCategory[] | null,
) {
  return !!categories?.some(hasExpressAppointment)
}

export function hasExpressAppointment(
  category?: BookingService | ServiceCategory | null,
) {
  return (
    !!category &&
    (("internalServiceId" in category &&
      checkServiceType.isWheelChangeService(category.internalServiceId)) ||
      ("internalServiceCategoryId" in category &&
        checkServiceType.isWheelChangeService(
          category.internalServiceCategoryId,
        ))) &&
    category.additionalServices?.some((additionalService) =>
      checkAdditionalServiceType.isExpressTermin(
        "id" in additionalService
          ? additionalService.id
          : additionalService.internalAdditionalServiceId,
      ),
    )
  )
}

export function hasExpressAppointmentAdditionalService(
  additionalService: AdditionalService,
) {
  return checkAdditionalServiceType.isExpressTermin(
    additionalService.internalAdditionalServiceId,
  )
}
