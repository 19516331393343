import { useFormikContext } from "formik"
import isArray from "lodash/isArray"
import isNumber from "lodash/isNumber"
import { useEffect } from "react"

import { scrollToElement } from "src/helpers/scrollToElement"

/**
 * A helper component that sets up scrolling to the first error item
 * This component must be used inside a Formik form
 * It is a component and not a hook because of Formik callback behaviour
 * @returns null
 */
export const ScrollToFieldError = () => {
  const { isValid, errors, isSubmitting } = useFormikContext<unknown>()

  useEffect(() => {
    if (
      isValid ||
      (isNumber(errors) && errors <= 0) ||
      (isArray(errors) && errors.length <= 0)
    ) {
      return
    }

    scrollToElement(`[data-error="true"]`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  return null
}
