export const scrollToElement = (
  selector: string,
  block: ScrollLogicalPosition = "center",
  timeout: number = 0,
) => {
  const element = document.querySelector(selector)
  if (!element) return

  // Scroll to first element
  setTimeout(() => {
    element.scrollIntoView({ behavior: "smooth", block: block })
  }, timeout)
}
