import { FC, PropsWithChildren } from "react"

import { Icon } from "src/components/common/icon"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./Loader.module.scss"

const bem = create(styles, "Loader")

export type LoaderProps = PropsWithChildren<{
  className?: string
  title?: string
  subTitle?: string
  message?: string
  showDefaultText?: boolean
  position?: "left" | "center"
  size?: "sm" | "md" | "lg"
}>

export const Loader: FC<LoaderProps> = ({
  className,
  title,
  subTitle,
  message,
  showDefaultText,
  position = "center",
  size = "md",
  children,
}) => {
  const { messages } = useTranslation()
  const translations = messages.components.common.loading

  const modifiers = {
    [position]: true,
    [size]: true,
  }

  return (
    <>
      {title && <h2 className={bem("title")}>{title}</h2>}
      <div className={bem(undefined, modifiers, className)} data-cy="loader">
        <Icon name="rim" className={bem("icon", modifiers)} />
        {showDefaultText && translations.status}
        {!!children && children}
      </div>
      {(subTitle || message) && (
        <div className={bem("subtitle-message-wrapper")}>
          {subTitle && <h2 className={bem("sub-title")}>{subTitle}</h2>}
          {message && <p className={bem("message")}>{message}</p>}
        </div>
      )}
    </>
  )
}
